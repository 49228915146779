
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useRef } from 'react';
import { GetHomeBrowseByPlacesQuery, GetHomeBrowseByPlacesQueryVariables, } from '@unreserved-frontend-v2/api/generated/graphql/get-home-browse-by-places.graphql';
import { PlaceAggregationsResponse, PlaceType, ResidentialPropertyForSaleListing, } from '@unreserved-frontend-v2/api/generated/graphql/types';
import { useGetHomeBrowseByPlaces } from '@unreserved-frontend-v2/api/hooks/use-get-home-browse-by-places';
import { useGetHomeListings } from '@unreserved-frontend-v2/api/hooks/use-get-home-listings';
import { useSSRBatchedData } from '@unreserved-frontend-v2/api/hooks/use-ssr-batched-data';
import { ListingCardsGroup, ListingCardsGroupVariant, } from '@unreserved-frontend-v2/modules/listings/components/listing-cards-group/listing-cards-group';
import { SearchByPlace } from '@unreserved-frontend-v2/modules/listings/components/search-by-place/search-by-place';
import { DesktopWidth } from '@unreserved-frontend-v2/ui/desktop-width/desktop-width';
import { Footer } from '@unreserved-frontend-v2/ui/footer/footer';
import { HeroSectionDesktop } from './hero-section-desktop';
import { BROWSE_BY_PLACE_DEFAULT_VARIABLES, RECENTLY_LISTED_DESKTOP_VARIABLES, RECENTLY_SOLD_DESKTOP_VARIABLES, } from './utils/constants';
import AppHeader from '../../header/app-header';
import { AppHeaderVariants } from '../../header/constants';
import { MainMenuKey } from '../../hooks/use-menu-items';
export function HomeDesktop() {
    const { query } = useRouter();
    const { t } = useTranslation();
    const parentPlaceSlug = (query['region'] as string) || 'ontario-ca';
    const serverVariables = useRef({
        recentlyListedInput: RECENTLY_LISTED_DESKTOP_VARIABLES,
        recentlySoldInput: RECENTLY_SOLD_DESKTOP_VARIABLES,
        browseByPlaceInput: {
            ...BROWSE_BY_PLACE_DEFAULT_VARIABLES,
            parentPlaceSlug
        },
        withRegions: true
    });
    /**
     * Everything batched for SSR
     */
    const { recentlySold, recentlyListed, listingsTabsFacets, regions } = useGetHomeListings(serverVariables.current);
    /**
     * City placeAggregations data from changing a region on the client side
     */
    const { data, isFetching } = useSSRBatchedData<GetHomeBrowseByPlacesQueryVariables, {
        data: PlaceAggregationsResponse;
        isFetching: boolean;
        isFetchedAfterMount: boolean;
    }>('browseByPlaces', {
        browseByPlaceInput: {
            ...BROWSE_BY_PLACE_DEFAULT_VARIABLES,
            parentPlaceSlug
        }
    }, useGetHomeBrowseByPlaces, {}, ['GetHomeListings']);
    return (<div className="bg-shades-25">
      <AppHeader noBorder={true} variant={AppHeaderVariants.GREEN} pageKey={MainMenuKey.NONE}/>
      <HeroSectionDesktop recentlyListed={recentlyListed} recentlySold={recentlySold} listingsTabsFacets={listingsTabsFacets}/>
      <DesktopWidth className="py-8">
        <div className="pb-4 text-lg font-bold">{t('listings:browse-by.region.title')}</div>
        <SearchByPlace parentPlaceType="region" selectedParentSlug={parentPlaceSlug} parentPlaces={regions} childPlaces={(data as unknown as GetHomeBrowseByPlacesQuery)?.browseByPlaces?.placeTypeAggregations.find((item) => item.placeType === PlaceType.City)?.aggregations} isFetching={isFetching}/>
        <ListingCardsGroup variant={ListingCardsGroupVariant.COLUMNS} cardClassName="col-span-3" className="pt-10" title={t('home:recently.listed-title')} listings={recentlyListed?.edges?.slice(3).map((edge) => edge?.node) as ResidentialPropertyForSaleListing[]}/>
        <ListingCardsGroup variant={ListingCardsGroupVariant.COLUMNS} cardClassName="col-span-3" className="pt-6" title={t('home:recently.sold-title')} listings={recentlySold?.edges?.slice(3).map((edge) => edge?.node) as ResidentialPropertyForSaleListing[]}/>
      </DesktopWidth>
      <Footer />
    </div>);
}
export default HomeDesktop;

    async function __Next_Translate__getStaticProps__18feeaf1ee8__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/desktop',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18feeaf1ee8__ as getStaticProps }
  